<template>
  <div class="address-management  container animate__animated animate__fadeIn container dp_f">
    <user-info></user-info>
    <div class="address-list">
      <el-scrollbar always>
        <div class="item" v-for="item in tableData" :key="item.id">
        <div class="top">
          <div class="name">{{ item.name }}</div>
          <div class="phone">{{ item.mobile }}</div>
        </div>
        <div class="address">{{ item.address }}</div>
        <div class="update-box">
          <div class="update" @click="edit(item)">
            <el-icon>
              <EditPen/>
            </el-icon>
          </div>
          <div class="delete" @click="del(item.id)">
            <el-icon>
              <Delete/>
            </el-icon>
          </div>
        </div>
      </div>
      <add-address class="mt_32" :list-length="tableData.length" @update="getAddressList"></add-address>
      </el-scrollbar>
    </div>
    <add-address-dia ref="addDia" @update="getAddressList" :address-info="chooseInfo"></add-address-dia>
  </div>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import UserInfo from './userInfo.vue'
import AddAddress from "@views/shop/personal/personal/components/addAddress.vue";
import {addressList, delAddress} from '@utils/api/address.js';
import {ElMessageBox} from "element-plus";
import AddAddressDia from "@views/shop/personal/personal/components/addAddressDia.vue";


const addDia = ref(null)
const {proxy} = getCurrentInstance();
const tableData = ref([])

const chooseInfo = ref({})
const getAddressList = async () => {
  const res = await addressList()
  if (res.code !== 0) {
    proxy.$message.error(res.msg)
    return
  }
  tableData.value = res.data.list || []
}

//删除该地址
const del = (id) => {
  ElMessageBox.confirm('确认删除收货信息？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    customClass: "zh-message-box shop",
  }).then(() => {
    //删除
    delAddress({id}).then(res => {
      if (res.code !== 0) {
        proxy.$message.error(res.msg)
        return
      }
      proxy.$message.success('删除成功')
      getAddressList()
    })
  })
}

//编辑地址
const edit = (item) => {
  chooseInfo.value = item
  addDia.value.dialogVisible = true
}

onMounted(() => {
  getAddressList()
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "addressManagement",
});
</script>
<style lang="scss" scoped>
.address-management {
  padding-top: 32px;
  align-items: flex-start !important;
  padding-bottom: 32px;
  .address-list {
    width: 900px;
    margin-left: 30px;
    height: calc(100vh - 260px);
    .item {
      width: 100%;
      border-bottom: 1px solid $shopBgColor;
      padding: 25px;
      position: relative;
      height: 96px;
      border-radius: 8px;
      overflow: hidden;
      transition: 0.5s all linear;

      &:hover {
        background-color: #F5F6FC;

        .update-box {
          right: 0;
        }
      }

      .top {
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: 14px;
        color: $shopFsColor;

        .name {
          font-weight: bold;
        }

        .phone {
          margin-left: 10px;
        }
      }

      .address {
        font-size: 14px;
        color: $shopFsColor2;
        margin-top: 12px;
      }

      .update-box {
        position: absolute;
        top: 0;
        right: -160px;
        width: 160px;
        height: 100%;
        transition: 0.1s all linear;
        display: flex;

        .update {
          background-color: #D7E3FC;
        }

        .delete {
          background-color: #FFD9D9;
        }

        > div {
          width: 80px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>