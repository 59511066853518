<template>
  <div class="add-address cur_p" v-bind="$attrs" @click="handleClick">
    <img src="@img/shop/register/add-address.png" alt="位元灵感"/>
    <span class="text">新增收货地址</span>
  </div>
  <add-address-dia ref="addDia" @update="update"></add-address-dia>
</template>

<script setup>
import {ref, getCurrentInstance} from "vue";
import AddAddressDia from "@views/shop/personal/personal/components/addAddressDia.vue";

const props = defineProps({
  listLength: {
    type: Number,
    default: 0
  }
})
const {proxy} = getCurrentInstance();
const emit = defineEmits(['update'])
const addDia = ref(null)
const handleClick = () => {
  if (props.listLength >= 10) {
    proxy.$message.error('最多只能添加10个收货地址')
    return
  }
  addDia.value.dialogVisible = true
}
const update = () => {
  emit('update')
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "addAddress",
});
</script>
<style lang="scss" scoped>
.add-address {
  display: flex;
  align-items: center;
  padding-left: 24px;
  width: max-content;

  img {
    width: 35px;
    height: 35px;
    margin-right: 8px;
  }

  .text {
    font-size: 14px;
    color: $shopFsColor2;
  }
}
</style>